/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import nurseCheckingPatient from '../images/heroes/nurse-checking-patient.jpg'
import purpleCheckMark from '../images/icons/purple-check.svg'
import womanMobilePhone from '../images/people/woman-on-mobile-phone.jpg'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-ppo']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna Medicare PPO Information | Call today 833-217-0308 to learn more ',
          description:
            'Learn about Aetna Medicare PPO plans, including the benefits, costs and coverage options. Call 833-217-0308 to learn more.',
        },
        path: '/ppo',
        promoCode: '151145',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={nurseCheckingPatient}
                  alt="elderly woman gets her pulse checked by a nurse with a stethoscope"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare PPO Plans{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-heart.svg"
                alt="location icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">What is a Medicare PPO plan?</Typography>
              <Typography variant="body">
                A preferred provider organization (PPO) plan allows you to visit
                any doctor or hospital that accepts Medicare. Although going
                out-of-network can cost more, there are no restrictions on which
                provider you see. Whether you’re visiting family across the
                country or sunbathing on a coast, if you have medical needs, you
                have medical care options.
              </Typography>
              <Typography variant="body">
                Unlike a Medicare HMO plan, Medicare PPO doesn’t require you to
                stick to a network or have a primary care physician. With that
                flexibility comes higher costs, so talk to a Licensed agent to
                see if a Medicare PPO plan is the right choice for you.
              </Typography>
              <Typography variant="h4">
                Medicare Advantage: PPO, HMO, and DSNP plans
              </Typography>
              <Typography variant="body">
                Medicare PPO plans are just one of the multiple Medicare
                Advantage options. If you’re looking to save money on a monthly
                premium, a Medicare HMO plan might be the plan for you. If you
                qualify for assistance, a Medicare DSNP plan will also help you
                with your prescription drug needs, as well as covering certain
                over-the-counter products. Call a Licensed agent today to
                discuss eligibility and get the plan that best suits your
                situation.
              </Typography>
              <div className="comparison-table">
                <table className="table-main align-text-left">
                  <thead>
                    <td></td>
                    <td>Aetna Medicare PPO</td>
                    <td>Aetna Medicare HMO</td>
                    <td>Aetna Medicare DNSP</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Use of provider network required</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <sup>*</sup>
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Primary care physician required</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Monthly premiums</td>
                      <td>Varies by plan</td>
                      <td>Varies by plan</td>
                      <td>Varies by plan</td>
                    </tr>
                    <tr>
                      <td>Prescription drug coverage included</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <sup>**</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>Dental, vision, and hearing coverage</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                    </tr>
                    <tr>
                      <td>Fitness benefit</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>Medicaid enrollment required</td>
                      <td>No</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="body" className="table-after">
                  *Use of provider network is required by an HMO plan unless
                  there’s an emergency.
                  <br />
                  **DSNP is a special type of a Medicare Advantage Prescription
                  Drug plan.
                </Typography>
              </div>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanMobilePhone}
              alt="an elderly woman talks on mobile phone while holding glasses and smiling"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in an Aetna Medicare Advantage PPO plan
              </Typography>
              <Typography variant="body">
                Is a Medicare PPO plan right for you? Then get started by
                tapping or clicking the button below. If you need help during
                the enrollment process, contact a friendly Licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <Typography variant="body">
                Need more info before you apply? No problem.{' '}
                <strong color="primary">
                  Request a FREE Medicare PPO quote
                </strong>{' '}
                by entering your zip code below.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
